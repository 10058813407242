import { mapState } from 'vuex';
import { get as _get } from 'lodash-es';

export default {
  props: ['item'],
  data: () => ({ pageIndex: null }),
  computed: {
    ...mapState(['inputs', 'template', 'variantId']),
    settings() {
      return this.item.data.form.customSettings;
    },
    classObj() {
      const result = {};
      if (this.item.desktop.textJustify !== null) {
        result[this.item.desktop.textJustify] = true;
      }
      return result;
    },
    orientationClass() {
      const result = {};
      if (this.item.desktop.orientation !== null) {
        result['vertical-alignment'] = this.item.desktop.orientation;
      } else {
        result['vertical-alignment'] = this.template.style['input-picker'].orientation;
      }
      return result;
    },
    type() {
      if (this.item.data.form !== undefined && this.settings !== undefined) {
        return this.settings.type;
      }
      return 'input';
    },
    customFieldIdTag() {
      const customId = this.settings.customId;
      if (['email', 'firstname', 'lastname'].includes(customId)) {
        return `visitor[${customId}]`;
      }

      return this.type === 'checkbox'
        ? `custom_fields[${customId}][]`
        : `custom_fields[${customId}]`;
    },
    itemStyleSize() {
      const globalSize = _get(this.template, 'style.inputs.size');
      const localSize = _get(this.item, 'desktop.smartSize.type');
      return localSize || globalSize;
    },
    inputValidations() {
      const validations = [];

      if (this.item.data.required) {
        validations.push('required');
      }

      if (this.type !== 'input') {
        if (this.type === 'email' && !this.item.data.required) {
          validations.push('optionalEmail');
        } else if (this.type === 'url' && !this.item.data.required) {
          validations.push('optionalUrl');
        } else if (this.type === 'phoneNumber' && !this.item.data.required) {
          validations.push('optionalPhoneNumber');
        } else {
          validations.push(this.type);
        }
      }

      if (this.type === 'email') {
        validations.push('allowBlock');
        if (this.item.data.form.customSettings.allowOnlyNewLead) {
          validations.push('allowOnlyNewLead');
        }
      }

      return validations.join(' ');
    },
    errorMessage() {
      if (
        (this.type === 'url' || this.type === 'email' || this.type === 'phoneNumber') &&
        !this.item.data.required
      ) {
        return this.$t('inputOptionalError', { inputType: this.type });
      }
      return this.item.data.errorMessage;
    },
  },
  methods: {
    // OM-765: becuse firefox doesn't send click on disabled inputs, use js to disable input and textarea
    disableInput(e) {
      e.target.blur();
    },
    optionValue(index) {
      return this.settings.options[index].key;
    },
    getUniqueId(index) {
      return `${this.settings.customId}-${this.variantId}-${this.item.pageId}-input-${index}`;
    },
  },
};
