<template lang="pug">
div(:id="`${item.uid}_wrapper`")
  .om-inputs-wrapper
    textarea.om-inputs.width-100(
      :id="item.uid"
      :name="customFieldIdTag"
      v-model="textAreaInitValue"
      @click="disableInput"
      :placeholder="item.data.epicPlaceholder"
      :data-validations="inputValidations"
      :data-error-text="errorMessage"
    )
</template>

<script>
  import inputs from '../../mixins/inputs';

  export default {
    mixins: [inputs],
    data() {
      return {
        textAreaInitValue: '',
      };
    },
  };
</script>
